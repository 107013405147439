<template>
  <div class="container">
    <v-form style="margin-bottom: 25px" v-model="isValid" ref="form">
      <div class="change-password-div">
        <div class="change-password-body">
          <span class="title">Change Password</span>
          <v-text-field
              style="margin-top: 35px; width: 100%;"
              label="Current Password"
              dense
              v-model="currentPass"
              :rules="[passwordRules.required]"
              required
              background-color="#ffffff"
              type="password"
              outlined>
          </v-text-field>
          <v-text-field
              style="width: 100%; margin-top: 15px; margin-bottom: 15px"
              label="New Password"
              dense
              :rules="[passwordRules.required, passwordRules.policy]"
              @keyup="matchPasswords"
              required
              v-model="newPass"
              type="password"
              outlined>
          </v-text-field>
          <v-text-field
              style="width: 100%; margin-bottom: 15px;"
              dense
              v-model="retypedPass"
              :rules="[passwordRules.required, passwordRules.policy, passwordRules.matchPassword]"
              required
              label="Re-Type Password"
              type="password"
              outlined>
          </v-text-field>
          <v-btn :disabled="!isValid" @click="changePassword" class="pw-btn">Change Password</v-btn>
        </div>
      </div>
    </v-form>
  </div>
</template>

<script>
import {PASSWORD_POLICY} from "cpp-js-util/CppUtil";
import encryptionLib from "cpp-js-util/encryption";

export default {
  name: "ChangePassword",
  data() {
    return {
      currentPass: '',
      retypedPass: '',
      newPass: '',
      isValid: true,
      passwordRules: {
        required: v => !!v || 'Password is required',
        policy: v => PASSWORD_POLICY.test(v) ||
            'Password must have 8+ characters and at least one uppercase, lowercase, digit & special character',
        matchPassword: v => (v === this.newPass) || 'Passwords don\'t match',
      },
    }
  },
  methods: {
    async changePassword() {
      this.$feedback.showLoading();
      let body = {
        accessToken: this.$store.getters.getAccessToken,
        phoneNumber: this.$store.getters.getUserPhoneNumber,
        previousPassword: this.currentPass,
        password: this.newPass,
      }

      let {nonce, publicKey} = await encryptionLib.getNonceAndRsaPublicKey(this.$cppClient, this.$feedback);
      body.previousPassword = encryptionLib.encryptWithRsaPublicKeyAndNonce(publicKey, nonce, body.previousPassword);
      body.password = encryptionLib.encryptWithRsaPublicKeyAndNonce(publicKey, nonce, body.password);

      this.$cppClient.post('/change-password', body)
          .then(() => {
            this.$feedback.showSuccessMessage('Password change successful. Please login again')
            this.$store.commit('clearPageState');
            this.$store.commit('logoutUser')
            this.$router.push({name: 'sign-in'})
          })
          .catch((err) => {
            this.$logger.loggable(err).log();
            this.$feedback.showFailed(err.response);
          })
    },
    matchPasswords() {
      if (this.retypedPass) {
        this.$refs.form.validate()
      }
    }
  }
}
</script>

<style scoped>

.container {
  margin-top: 70px !important;
}

.change-password-div {
  display: flex;
  height: 90vh;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.title {
  width: 178px;
  height: 25px;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.82;
  letter-spacing: normal;
  text-align: center;
  color: #464646;
}

.change-password-body {
  display: flex;
  width: 550px;
  flex-direction: column;
  border-radius: 5px;
  background-color: #fcfcfc;
  align-items: center;
  justify-content: center;
  padding-top: 53px;
  padding-bottom: 54px;
  padding-left: 30px;
  padding-right: 30px;
}

.pw-btn {
  width: 171px;
  height: 40px;
  border-radius: 4px;
  color: #ffffff !important;
  background-color: #e2136e !important;
}

@media screen and (max-width: 1000px) {
  .container {
    margin-top: unset !important;
  }
}
</style>
